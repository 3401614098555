import { Children, createContext, useContext, useEffect, useState } from "react";


const DeviceContext= createContext()

export const DeviceProvider=  ({children})=>{
    const [isMobile, setIsMobile]= useState(false)

    useEffect(()=>{
        const checkIsMobile= ()=>{
            const mobileBreakPoint= 600
            setIsMobile(window.innerWidth <= mobileBreakPoint)
        }

        checkIsMobile()

        window.addEventListener("resize", checkIsMobile)

        return ()=>{
            window.removeEventListener("resize", checkIsMobile)
        }

    },[])


    return (
        <DeviceContext.Provider value={{isMobile}}>
            {children}
        </DeviceContext.Provider>
    )
}

export const useDevice= ()=> useContext(DeviceContext)