import React, { useEffect, useRef, useState } from 'react'
import FixedMenu from '../components/FixedMenu/FixedMenu'
import "../css/AnalyticsModern.css"
import axios from 'axios';
import { useUser } from '../Context/UserContext';
import SpinnerLoader from '../components/Loader/SpinnerLoader'
import GoalCircle from '../components/Analytics/GoalCircle'
import { NavLink, useLocation } from 'react-router-dom';
import CryptoLoadingAnimation from '../components/LoadingAnimation/CryptoLoadingAnimation';
import { motion } from "framer-motion"
import ApiConfig from '../apiConfig';
import { useNavigate } from 'react-router-dom';
import AnalyticsDataBlock from '../components/Analytics/AnalyticsDataBlock'
import euro from "../assets/Analytics/euro.png"
import percent from "../assets/Analytics/percent.png"
import rewards from "../assets/Analytics/rewards.png"
import moneybag from "../assets/Analytics/moneybag.png"
import adler from "../assets/Analytics/adler.png"
import goal from "../assets/Analytics/goal.png"
import GenericModernModal from '../components/Modal/GenericModernModal'

export default function AnalyticsModern() {

  const portfolioValue = localStorage.getItem("lastPrice");
  const rawPortfValue = localStorage.getItem("rawLastPrice");
  const baseURL = ApiConfig.baseUrl
  const [userStethReward, setUserStethReward] = useState();
  const { userData: user } = useUser();
  const btcPrice = useRef();
  const ethPrice = useRef();
  const qntPrice = useRef();
  const userAmounts = useRef([]);
  const [nomValue, setNomValue] = useState()
  const [rawNomValue, setRawNomValue] = useState();
  const [pnlColor, setPnlColor] = useState()
  const [chartData, setChartData] = useState()
  const [absoluteGoalDiff, setAbsoluteGoalDiff] = useState();
  const [adlerKapital, setAdlerKapital] = useState();
  const [adlerBtcAmount, setAdlerBtcAmount] = useState();
  const [adlerEthAmount, setAdlerEthAmount] = useState();
  const [adlerStethAmount, setAdlerStethAmount] = useState();
  const [adlerQntAmount, setAdlerQntAmount] = useState();
  const [showLoadingAnimation, setShowLoadingAnimation] = useState(true)
  const [showModal, setShowMoadal] = useState(false)
  const [pageScrollable, setPageScrollable]= useState("")

  const navigate = useNavigate();

  let userData = JSON.stringify({
    "username": user.username,
    "password": user.password
  });

  function lidoDataConfig(address, name) {
    return {
        method: 'post',
        maxBodyLength: Infinity,
        url: `https://adlerbackend-adleranalytics.koyeb.app/api/crypto/lidoData/${address}`,
        headers: {
            'Content-Type': 'application/json'
        },
        data: userData
    };
}

  const ethAdresses = [
    { name: "Bueno", address: "0x50cBc94CDcd883AF15163A39a98E7d06daDE4C56" },
    { name: "Tutti", address: "0x569eaa46d294874A1211d5c0Ac0FA59e65543FC2" },
    { name: "Niggi", address: "0xe792dc909383AF355a9990C7cD230D92B83d0FE1" },
    { name: "Ralle", address: "0xB95BD08D0759ed5eE40E221C2f338f8A11A56238" },
  ]

  async function fetchStakingRewards(address, name, setUser) {
    address = null;
    switch (name) {
      case "Bueno": { address = ethAdresses[0].address; break; }
      case "Tutti": { address = ethAdresses[1].address; break; }
      case "Niggi": { address = ethAdresses[2].address; break; }
      case "Ralle": { address = ethAdresses[3].address; break; }
    }
  
    let rewardsEth = 0;
  
    if (address) {
      const response = await axios.request(lidoDataConfig(address, name));
      const data = response?.data;
      const rewardsWei = data?.totals.ethRewards;
      rewardsEth = rewardsWei * 10e-19;
    }
  
    if (setUser) {
      setUserStethReward(rewardsEth);
    }
  
    return rewardsEth;
  }
  


  let userAmountsConfig = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${baseURL}/api/transactions/amounts`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: userData
  };

  let nomValueConfig = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${baseURL}/api/transactions/nomvalue`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: userData
  };

  let orderConfig = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${baseURL}/api/transactions/ordered`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: userData
  };


  const fetchUserAmounts = async () => {
    try {
      const response = await axios.request(userAmountsConfig)
        .then(res => { userAmounts.current = res.data })


    } catch (err) {
      console.error(err)
    }
  }

  const fetchBtcEthPrice = async () => {
    try {
      const response = await axios.get(`${baseURL}/api/crypto/ethbtcPrice`);
      const btcPrice1 = response.data.data.BTC[0].quote.EUR.price;
      const ethPrice1 = response.data.data.ETH[0].quote.EUR.price;
      const qntPrice1 = response.data.data.QNT[0].quote.EUR.price;


      btcPrice.current = btcPrice1;
      ethPrice.current = ethPrice1;
      qntPrice.current = qntPrice1;
    } catch (error) {
      console.error(error);
    }
  }

  const fetchNomValue = async () => {
    try {
      const response = await axios.request(nomValueConfig);
      const value = response.data.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' });
      setNomValue(value)
      setRawNomValue(response.data)
    } catch (err) {
      console.error(err)
    }
  }

  const currValue = localStorage.getItem("rawLastPrice")
  const euroFormAbsolutePerf = (parseFloat(currValue) - rawNomValue).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' });


  async function fetchAdlerKapital() {
    let adlerStethRewards = await fetchStakingRewards(null, "Bueno", false);
    adlerStethRewards += await fetchStakingRewards(null, "Tutti", false);
    adlerStethRewards += await fetchStakingRewards(null, "Niggi", false);


    const response = await axios.get((`${baseURL}/api/crypto/adlerKapital`))

    if (adlerStethRewards) {
      setAdlerKapital(response.data + (adlerStethRewards * ethPrice.current))
      localStorage.setItem("adlerKapital", (response.data+ (adlerStethRewards * ethPrice.current)).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }))
    } else {
      setAdlerKapital(response.data)
      localStorage.setItem("adlerKapital", response.data.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }))
    }


    const capital = await axios.get(`${baseURL}/api/transactions/adlerAllocation`).then((result) => {
      setAdlerBtcAmount(result.data[1])
      setAdlerEthAmount(result.data[2])
      setAdlerStethAmount(result.data[3])
      setAdlerQntAmount(result.data[4])
    })
  }

  function toggleModal(){
    setShowMoadal(!showModal)
  }

  function togglePageScrollable(){
    if(pageScrollable==="none"){
      setPageScrollable("")
    }else{
      setPageScrollable("none")
    }
  }


  useEffect(() => {

    async function init() {
      setAdlerKapital(localStorage.getItem("adlerKapital"))
      await fetchStakingRewards(null, user.username, true)
      await fetchBtcEthPrice()
      await fetchUserAmounts()
      await fetchNomValue()
      calculateGoalData()
      setShowLoadingAnimation(false)
      await fetchAdlerKapital();

    }

    init()

  }, [])

  let relPnL = -((1 - rawPortfValue / rawNomValue) * 100).toFixed(2);
  if(!relPnL) relPnL="0.00"
  let color
  if (relPnL > 0) { color = "#2dd649" } else { color = "#ff303070" }
  const rawStakingRewardsEur = (userStethReward * ethPrice.current)
  const stakingRewardsEur = (userStethReward * ethPrice.current).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })


  const [goalPercentage, setGoalP] = useState(() => {
    const percentage = localStorage.getItem("goalPercentage")
    return percentage ? percentage : 0
  })

  async function calculateGoalData() {
    const btcAmount = userAmounts.current[0]
    const ethAmount = userAmounts.current[1] + userAmounts.current[2] + JSON.parse(localStorage.getItem("stethRewards"))
    const qntAmount = userAmounts.current[3]

    const btcGoal = localStorage.getItem("btcGoal")
    const ethGoal = localStorage.getItem("ethGoal")
    const qntGoal = localStorage.getItem("qntGoal")

    if (btcGoal <= 0 && ethGoal <= 0 && qntGoal <= 0) { return 0 }

    let btcGoalDiff = (btcGoal - btcAmount) * btcPrice.current
    let ethGoalDiff = (ethGoal - ethAmount) * ethPrice.current
    let qntGoalDiff = (qntGoal - qntAmount) * qntPrice.current

    if (btcGoalDiff < 0) { btcGoalDiff = 0 }
    if (ethGoalDiff < 0) { ethGoalDiff = 0 }
    if (qntGoalDiff < 0) { qntGoalDiff = 0 }

    const absoluteGoalDiff = btcGoalDiff + ethGoalDiff + qntGoalDiff;

    setAbsoluteGoalDiff(absoluteGoalDiff)

    const realPercentage = 1 - ((btcGoalDiff + ethGoalDiff + qntGoalDiff) / (btcGoal * btcPrice.current + ethGoal * ethPrice.current + qntGoal * qntPrice.current))

    localStorage.setItem("goalPercentage", realPercentage * 100)
    setGoalP(realPercentage * 100)
    return realPercentage * 100;
  }


  return (

    <div style={{touchAction:`${pageScrollable}`}}>
      {!showLoadingAnimation ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <div className='main-container-analytics'>
            <div className='headline-container mt-2'>
              <h2 className='headline1'>Current Balance</h2>
              <h2 className='current-balance'>{portfolioValue}</h2>
            </div>

            <NavLink to="/goals">
              <div className='chart-container'>
                <GoalCircle percentage={goalPercentage}></GoalCircle>
              </div>
            </NavLink>


            <div style={{ display: "flex", flexDirection: "row", gap: "18px", marginLeft: "18px", marginTop: "28px" }}>
              <AnalyticsDataBlock title={"Missing"} img={goal} value={absoluteGoalDiff ? absoluteGoalDiff.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' }) : "0,00 €"}></AnalyticsDataBlock>
              <AnalyticsDataBlock title={"Adler Assets"} img={adler} value={adlerKapital ? adlerKapital.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })  : <SpinnerLoader/>} expand={true} modalfunction={toggleModal} ></AnalyticsDataBlock>
            </div>

            <div style={{ display: "flex", flexDirection: "row", gap: "18px", marginLeft: "18px", marginTop: "18px" }}>
              <AnalyticsDataBlock title={"Relative"} img={percent} value={relPnL + "%"} color={`${color}`}></AnalyticsDataBlock>
              <AnalyticsDataBlock title={"Absolute"} img={euro} value={euroFormAbsolutePerf} color={`${color}`} ></AnalyticsDataBlock>
            </div>

            <div style={{ display: "flex", flexDirection: "row", gap: "18px", marginLeft: "18px", marginTop: "18px" }}>
              <AnalyticsDataBlock title={"Nominal Value"} img={moneybag} value={nomValue}></AnalyticsDataBlock>
              <AnalyticsDataBlock title={"Staking Rewards"} img={rewards} value={stakingRewardsEur} color={`${color}`} ></AnalyticsDataBlock>
            </div>

            <div>
              {showModal? (<GenericModernModal content={<h1 style={{marginTop:"400px"}}>Under Construction</h1>} modalVisible={showModal} toggleVisibleStateInParent={toggleModal} closingThreshold={150} toggleScrollFunction={togglePageScrollable} ></GenericModernModal>):(<p></p>)}
            </div>


          </div>
        </motion.div>)
        :
        <CryptoLoadingAnimation/>
      }


      <FixedMenu></FixedMenu>
    </div >
  )
}
