import React, { useEffect, useRef, useState } from 'react'
import Lottie from "lottie-react";
import CryptoLoadingAnimation from '../../LoadingAnimation/CryptoLoadingAnimation';
import devMode from "../../../assets/Login/devMode.png"
import "./HomeLoading.css"
import checkAnimation from "../../../assets/ActionButtons/checkAnimation.json"

function HomeLoading({actions}) {

    const [showDebug, setShowDebug]= useState(false)
    const [thisActions, setThisActions]= useState([])
    const currentActionId = useRef(1)
   
    function toggleDebugMode(){
        setShowDebug(!showDebug)
    }

    const loadingContent=()=>{
        return (
            <div>
                <img onClick={toggleDebugMode} className='devmodeIcon' src={devMode}></img>
                <CryptoLoadingAnimation/>
            </div>
        )
    }


    useEffect(()=>{
        const intervall = setInterval(() => {
            setThisActions((prevActions) => {
                const updatedActions = [...prevActions];
                const index= prevActions.length-1
                updatedActions[index] = {...updatedActions[index], timeElapsed: updatedActions[index].timeElapsed + 0.1,};
                return updatedActions;
              });
        }, 100)

       
        return ()=> {clearInterval(intervall); /* clearInterval(intervall2) */}
    },[])


    useEffect(()=>{
        setThisActions(actions)
    },[actions])


    return (
        <div>
            {showDebug ? (
                <div style={{marginTop:"20%"}}>
                <img onClick={toggleDebugMode} className='devmodeIcon' src={devMode}></img>
                {thisActions.map((action, i)=>(
                        <li key={action.id} className="actionListEntry">
                            <p className='actionName'>{action.name}</p>
                            {action.completed ? (<Lottie style={{width:"70px", marginTop:"-18px"}} loop={false} animationData={checkAnimation}></Lottie>)
                            :(<p className='timer'>{action.timeElapsed.toFixed(1) +"s"}</p>)}

                        </li>
                ))}
                
            </div>
            ) : loadingContent()}
        </div>
    )
}

export default HomeLoading
